const action = {
  POBLACION_CREATE_REQUEST: 'POBLACION_CREATE_REQUEST',
  POBLACION_CREATE_SUCCESS: 'POBLACION_CREATE_SUCCESS',
  POBLACION_CREATE_ERROR: 'POBLACION_CREATE_ERROR',

  POBLACION_UPDATE_REQUEST: 'POBLACION_UPDATE_REQUEST',
  POBLACION_UPDATE_SUCCESS: 'POBLACION_UPDATE_SUCCESS',
  POBLACION_UPDATE_ERROR: 'POBLACION_UPDATE_ERROR',

  POBLACIONS_FETCH_REQUEST: 'POBLACIONS_FETCH_REQUEST',
  POBLACIONS_FETCH_SUCCESS: 'POBLACIONS_FETCH_SUCCESS',
  POBLACIONS_FETCH_ERROR: 'POBLACIONS_FETCH_ERROR',

  POBLACION_UPDATE_MODAL: 'POBLACION_UPDATE_MODAL',
  POBLACION_SHOW_MODAL: 'POBLACION_SHOW_MODAL',
  POBLACION_HIDE_MODAL: 'POBLACION_HIDE_MODAL',

  // Acciones
  addPoblacion: data => ({
    types: [action.POBLACION_CREATE_REQUEST, action.POBLACION_CREATE_SUCCESS, action.POBLACION_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/poblaciones`,
        data,
      }
    }
  }),
  editPoblacion: data => ({
    types: [action.POBLACION_UPDATE_REQUEST, action.POBLACION_UPDATE_SUCCESS, action.POBLACION_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/poblaciones/${data.id}`,
        data,
      }
    }
  }),
  deletePoblacion: data => ({
    types: [action.POBLACION_UPDATE_REQUEST, action.POBLACION_UPDATE_SUCCESS, action.POBLACION_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/poblaciones/${data.id}`,
      }
    }
  }),
  getPoblacions: empresaId => ({
    types: [action.POBLACIONS_FETCH_REQUEST, action.POBLACIONS_FETCH_SUCCESS, action.POBLACIONS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/poblaciones?empresaId=${empresaId}`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.POBLACION_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.POBLACION_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.POBLACION_HIDE_MODAL });
    }
  },
};
export default action;
