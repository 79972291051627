import Cookies from "js-cookie";
import { message, notification } from "antd";
import axios from "axios";
import { push } from "react-router-redux";
import { getToken, clearToken } from "@/lib/helpers/utility";
import AuthHelper from "@/lib/helpers/authHelper";
import config from "@/config";
import { API } from "@/config/api";

const actions = {
  AUTHENTICATE: "auth/AUTHENTICATE",
  SET_CURRENT_USER: "auth/SET_CURRENT_USER",
  LOGIN_REQUEST: "auth/LOGIN_REQUEST",
  LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",
  LOGIN_ERROR: "auth/LOGIN_ERROR",
  REGISTRO_REQUEST: "auth/REGISTRO_REQUEST",
  REGISTRO_SUCCESS: "auth/REGISTRO_SUCCESS",
  REGISTRO_ERROR: "auth/REGISTRO_ERROR",
  REGISTRO_DIRECCION_SUCCESS: "auth/REGISTRO_DIRECCION_SUCCESS",
  UPDATE_DIRECCION_REQUEST: "auth/UPDATE_DIRECCION_REQUEST",
  UPDATE_DIRECCION_SUCCESS: "auth/UPDATE_DIRECCION_SUCCESS",
  UPDATE_DIRECCION_ERROR: "auth/UPDATE_DIRECCION_ERROR",
  CHECK_AUTHORIZATION: "auth/CHECK_AUTHORIZATION",
  LOGOUT: "auth/LOGOUT",

  RECUPERAR_PASS_REQUEST: "auth/RECUPERAR_PASS_REQUEST",
  RECUPERAR_PASS_SUCCESS: "auth/RECUPERAR_PASS_SUCCESS",
  RECUPERAR_PASS_ERROR: "auth/RECUPERAR_PASS_ERROR",

  RESET_PASS_REQUEST: "auth/RESET_PASS_REQUEST",
  RESET_PASS_SUCCESS: "auth/RESET_PASS_SUCCESS",
  RESET_PASS_ERROR: "auth/RESET_PASS_ERROR",

  UPDATE_PERFIL_SUCCESS: "auth/UPDATE_PERFIL_SUCCESS",

  OPEN_MODAL_SUCCESS: "auth/OPEN_MODAL_SUCCESS",
  OPEN_MODAL_ASIENTO: "auth/OPEN_MODAL_ASIENTO",

  REGISTRO_EMPRESA_REQUEST: "auth/REGISTRO_EMPRESA_REQUEST",
  REGISTRO_EMPRESA_SUCCESS: "auth/REGISTRO_EMPRESA_SUCCESS",
  REGISTRO_EMPRESA_ERROR: "auth/REGISTRO_EMPRESA_ERROR",

  USER_MENU_REQUEST: "auth/USER_MENU_REQUEST",
  USER_MENU_SUCCESS: "auth/USER_MENU_SUCCESS",
  USER_MENU_ERROR: "auth/USER_MENU_ERROR",

  LOGIN_SUSCRIPCION_ERROR: "auth/LOGIN_SUSCRIPCION_ERROR",

  getUserMenu: (id) => ({
    types: [
      actions.USER_MENU_REQUEST,
      actions.USER_MENU_SUCCESS,
      actions.USER_MENU_ERROR,
    ],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/menus/user/${id}`,
      },
    },
  }),

  openModal: () => (dispatch) => dispatch({ type: actions.OPEN_MODAL_SUCCESS }),

  openModalAsiento: (payload) => (dispatch) =>
    dispatch({ type: actions.OPEN_MODAL_ASIENTO, payload }),

  // checkAuthorization: () => ({ type: actions.actions.CHECK_AUTHORIZATION }),
  setCurrentUser: (user, token) => (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: actions.SET_CURRENT_USER,
        user,
        token,
      });

      Cookies.set("user", user);

      dispatch({
        type: actions.AUTHENTICATE,
        authenticated: true,
      });

      resolve(user);
    }),

  /**
   * checkAuthorization
   */
  checkAuthorization: () => (dispatch) => {
    dispatch({ type: actions.CHECK_AUTHORIZATION });
    const { token } = AuthHelper.checkExpirity(getToken().get("idToken"));
    if (token) {
      let userFromCookie = Cookies.getJSON("user");
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: userFromCookie,
        token: token,
        idToken: token,
      });
    }
  },

  establishCurrentUser: () => (dispatch) =>
    new Promise((resolve) => {
      let userFromCookie = Cookies.getJSON("user");
      const token = getToken().get("idToken");
      // console.log('Fucking idToken', token);
      // console.log('userFromCookie', userFromCookie);
      if (userFromCookie) {
        dispatch(this.setCurrentUser(userFromCookie, token));
        resolve(userFromCookie);
      } else {
        resolve({});
      }
    }),

  loginUser: (email, password) => (dispatch) =>
    new Promise((resolve, reject) => {
      const user = {
        email,
        password,
        name: "Daniel Pineda",
      };

      dispatch(this.setCurrentUser(user));
      resolve(user);
    }),

  logoutUser: () => (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: actions.AUTHENTICATE,
        authenticated: false,
        token: null,
      });

      dispatch({
        type: actions.SET_CURRENT_USER,
        user: {},
        token: null,
      });

      Cookies.remove("user");
      clearToken();
      resolve({});
    }),

  /**
   * Login de usuario
   */
  login: (data, history) => (dispatch, getState) => {
    dispatch({ type: actions.LOGIN_REQUEST });
    axios
      .post(`${config.apiUrl}/login`, data)
      .then((res) => {
        dispatch({
          type: actions.LOGIN_SUCCESS,
          payload: res.data.data,
          token: res.data.token,
        });
        localStorage.setItem("id_token", res.data.token);
        localStorage.setItem("empresaId", res.data.data.empresa.id);
        Cookies.set("user", res.data.data, { expires: 365 });
        console.log('res.data.data', res.data.data);
        if (res.data.data.tipo === "empresa" && !res.data.data.completed) {
          history.push("/configuracion");
        } else {
          history.push("/admin");
          message.success(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.err.message === "ErrorSuscripcion" && err.response.data.user.tipo === 'empresa') {
          history.push("/suscripcion");
          Cookies.set("user", err.response.data.user, { expires: 365 });
          dispatch({
            type: actions.LOGIN_SUSCRIPCION_ERROR,
            payload: err.response.data.user,
          });
        } else {
          message.error(err.response.data.err.description);
          dispatch({ type: actions.LOGIN_ERROR });
        }
      });
  },

  /**
   * Login de usuario
   */
  loginSocial: (user, token) => (dispatch) => {
    dispatch({
      type: actions.LOGIN_SUCCESS,
      payload: user,
      token: token,
    });
    // dispatch({ type: actions.CLOSE_MODAL_SUCCESS });
    message.success(`Hola ${user.nombre}, bienvenido`);
    // guardar el token en localStorage
    localStorage.setItem("id_token", token);
    Cookies.set("user", user, { expires: 365 });
  },

  /**
   * Registro de usuario
   */
  register: (data) => (dispatch, getState) => {
    dispatch({ type: actions.REGISTRO_REQUEST });
    axios
      .post(`${config.apiUrl}/registro`, data)
      .then((res) => {
        console.log(res);
        dispatch({
          type: actions.REGISTRO_SUCCESS,
          payload: res.data.data,
          token: res.data.token,
        });
        message.success(res.data.message);
        localStorage.setItem("id_token", res.data.token);
        localStorage.setItem("empresaId", res.data.data.empresa.id);
        const selected = getState().compra.selected;
        if (selected.length === 0 && res.data.asiento) {
          localStorage.setItem("tiempo", new Date());
        }
        Cookies.set("user", res.data.data, { expires: 365 });
      })
      .catch((err) => {
        notification.error(err.response.data.err.notificacion);
        dispatch({
          type: actions.REGISTRO_ERROR,
          payload: err.response.data.err.notificacion,
        });
      });
  },

  /**
   * Registro de usuario empresa
   */
  registroEmpresa: (data, history) => (dispatch, getState) => {
    dispatch({ type: actions.REGISTRO_EMPRESA_REQUEST });
    axios
      .post(`${config.apiUrl}/registroEmpresa`, data)
      .then((res) => {
        console.log(res);
        localStorage.setItem("id_token", res.data.token);
        localStorage.setItem("empresaId", res.data.data.empresa.id);
        localStorage.setItem("time", new Date()); // Guardar tiempo
        Cookies.set("user", res.data.data, { expires: 365 });
        dispatch({
          type: actions.REGISTRO_EMPRESA_SUCCESS,
          payload: res.data.data,
          token: res.data.token,
        });
        notification.success(res.data.notificacion);
        history.push("/configuracion");
        // history.push("/admin");
      })
      .catch((err) => {
        dispatch({
          type: actions.REGISTRO_EMPRESA_ERROR,
          payload: err.response.data.err.notificacion,
        });
      });
  },

  /**
   * Registro de usuario empresa
   */
  redirectAdmin: () => (dispatch) => dispatch(push("/admin")),

  /**
   * Registro de dirección de usuarios
   */
  registerDireccion: (data) => (dispatch, getState) => {
    axios
      .post(`${config.apiUrl}/direccion`, data, {
        headers: { token: getState().auth.token },
      })
      .then((res) => {
        dispatch({
          type: actions.REGISTRO_DIRECCION_SUCCESS,
          payload: res.data.data,
        });
        dispatch(push("/cava/pago"));
        Cookies.set("user", res.data.data, { expires: 365 });
      })
      .catch((err) => {
        message.error(err.response.data.err.message);
      });
  },
  registerDireccionPage: (data) => (dispatch, getState) => {
    axios
      .post(`${config.apiUrl}/direccion`, data, {
        headers: { token: getState().auth.token },
      })
      .then((res) => {
        dispatch({
          type: actions.REGISTRO_DIRECCION_SUCCESS,
          payload: res.data.data,
        });
        notification.success({
          message: "Datos direccion",
          description: "Dirección actualizada",
        });
        Cookies.set("user", res.data.data, { expires: 365 });
      })
      .catch((err) => {
        message.error(err.response.data.err.message);
      });
  },
  updateDireccion: (data) => ({
    types: [
      actions.UPDATE_DIRECCION_REQUEST,
      actions.UPDATE_DIRECCION_SUCCESS,
      actions.UPDATE_DIRECCION_ERROR,
    ],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/direccion/${data.id}`,
        data,
      },
    },
  }),
  registerTarjeta: (data) => ({
    types: [
      actions.UPDATE_DIRECCION_REQUEST,
      actions.UPDATE_DIRECCION_SUCCESS,
      actions.UPDATE_DIRECCION_ERROR,
    ],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: "/cards",
        data,
      },
    },
  }),
  deleteTarjeta: (id) => ({
    types: [
      actions.UPDATE_DIRECCION_REQUEST,
      actions.UPDATE_DIRECCION_SUCCESS,
      actions.UPDATE_DIRECCION_ERROR,
    ],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/cards/${id}`,
      },
    },
  }),
  recuperarPass: (data) => (dispatch, getState) => {
    dispatch({ type: actions.RECUPERAR_PASS_REQUEST });
    axios
      .post(`${config.apiUrl}/auth/recuperar`, data)
      .then((res) => {
        dispatch({
          type: actions.RECUPERAR_PASS_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => message.error(err.response.data.err.message));
  },
  resetPass: (data) => (dispatch, getState) => {
    dispatch({ type: actions.RESET_PASS_REQUEST });
    axios
      .post(`${config.apiUrl}/auth/reset`, data)
      .then((res) => {
        dispatch({ type: actions.RESET_PASS_SUCCESS, payload: res.data.data });
      })
      .catch((err) => {
        dispatch({ type: actions.RESET_PASS_ERROR });
        message.error(err.response.data.err.message);
      });
  },
  updatePerfil: (data) => (dispatch, getState) => {
    axios
      .post(`${config.apiUrl}/auth/updatePerfil`, data, {
        headers: { token: getState().auth.token },
      })
      .then((res) => {
        dispatch({
          type: actions.UPDATE_PERFIL_SUCCESS,
          payload: res.data.data,
        });
        Cookies.set("user", res.data.data, { expires: 365 });
        notification.success({
          message: "Perfil actualizado",
          description: res.data.message,
        });
      })
      .catch((err) =>
        notification.error({
          message: "Error",
          description: err.response.data.err.message,
        })
      );
  },
  updateTarjetas: (data) => (dispatch, getState) => {
    API.post("/cards", data).then((res) => {
      dispatch({ type: actions.UPDATE_PERFIL_SUCCESS, payload: res.data.data });
    });
  },
  updateListTarjetas: (payload) => ({
    type: actions.UPDATE_PERFIL_SUCCESS,
    payload,
  }),
  logout: (data) => (dispatch) => {
    Cookies.remove("user");
    dispatch({ type: actions.LOGOUT });
  },
};
export default actions;
