import auth from './auth/reducer';
import app from './app/reducer';
import languageSwitcher from './languageSwitcher/reducer';
import search from './search/reducer';
import clientes from './clientes/reducer';
import planes from './planes/reducer';
import compra from './compra/reducer';
import poblaciones from './poblaciones/reducer';
import unidades from './unidades/reducer';
import empleados from './empleados/reducer';
import servicios from './servicios/reducer';
import reservaciones from './reservaciones/reducer';
import movimientos from './movimientos/reducer';
import historial_cancelacion from './historial_cancelacion/reducer';
import cupones from './cupones/reducer';
import descuentos from './descuentos/reducer';
import paquetes from './paquetes/reducer';
import marcas from './marcas/reducer';
import tickets from './tickets/reducer';
import config from './config/reducer';
import categorias from './categorias/reducer';

export default {
  auth,
  app,
  clientes,
  search,
  languageSwitcher,
  planes,
  compra,
  poblaciones,
  unidades,
  empleados,
  servicios,
  reservaciones,
  movimientos,
  historial_cancelacion,
  cupones,
  descuentos,
  paquetes,
  marcas,
  tickets,
  config,
  categorias,
};
