// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
  visible: false,
};

export default function solicitudReducer(state = init, action) {
  const { payload } = action;
  switch (action.type) {
    // FETCH
    case actions.TICKETS_FETCH_REQUEST:
      return { ...state, isFetching: true };

    case actions.TICKETS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        pagination: payload.data.pagination,
        isFetching: false,
      };
    case actions.TICKETS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false };
    // MOVIMIENTOS
    case actions.MOVIMIENTOS_FETCH_REQUEST:
      return { ...state, isFetching: true };

    case actions.MOVIMIENTOS_FETCH_SUCCESS:
      return {
        ...state,
        movimientos: payload.data.data || [],
        pagination: payload.data.pagination,
        isFetching: false,
      };
    case actions.MOVIMIENTOS_FETCH_ERROR:
      return { ...state, isFetching: false };

    case actions.TICKETS_TOTAL_FETCH_SUCCESS:
      return { ...state, total: payload.data.data || [] };

    case actions.UPDATE_LIST_TICKETS_SUCCESS:
      return {
        ...state,
        list: state.list.map(x => x.id === payload.id ? payload : x),
      };

    case actions.UPDATE_LIST_MOVIMIENTOS_SUCCESS:
      return {
        ...state,
        movimientos: state.movimientos.map(x => x.id === payload.id ? payload : x),
      };

    case actions.OPEN_DRAWER_INFO:
      return {
        ...state,
        visible: true,
        type: 'view',
        // corrida: action.payload,
      };

    case actions.CLOSE_DRAWER_INFO:
      return {
        ...state,
        visible: false,
        type: 'view',
        corrida: {},
      };

    case actions.MOVIMIENTOS_SHOW_MODAL:
      return { ...state, modalVisible: true, modalType: 'create' };

    case actions.MOVIMIENTOS_HIDE_MODAL:
      return { ...state, modalVisible: false, modalType: 'create' };

    default:
      return state;
  }
}
