import React, { lazy, Suspense } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import Loader from "@/components/utility/loader";

const Dashboard = lazy(() => import("@/containers/Dashboard/Dashboard"));

const publicRoutes = [
  {
    path: "/",
    exact: true,
    component: lazy(() => import("@/containers/Pages/signin")),
  },
  {
    path: "/registro",
    exact: true,
    component: lazy(() => import("@/containers/Pages/registro")),
  },
  {
    path: "/recuperar",
    exact: true,
    component: lazy(() => import("@/containers/Pages/recuperar")),
  },
  {
    path: "/reset/:token",
    exact: true,
    component: lazy(() => import("@/containers/Pages/reset")),
  },
  {
    path: "/configuracion",
    exact: true,
    component: lazy(() => import("@/containers/Pages/Configuracion")),
  },
  {
    path: "/suscripcion",
    exact: true,
    component: lazy(() => import("@/containers/Pages/Suscripcion")),
  },
];

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.auth.token);
  if (isLoggedIn) return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
        pathname: "/",
        state: { from: location },
      }}
    />
  );
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/admin">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
