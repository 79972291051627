// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
  visible: false,
};

export default function solicitudReducer(state = init, action) {
  const { payload } = action;
  switch (action.type) {
    // FETCH
    case actions.CANCELACIONES_FETCH_REQUEST:
      return { ...state, isFetching: true };

    case actions.CANCELACIONES_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        pagination: payload.data.pagination,
        isFetching: false,
      };
    case actions.CANCELACIONES_FETCH_ERROR:
      return { ...state, list: [], isFetching: false };

    default:
      return state;
  }
}
