const action = {
  EMPLEADO_CREATE_REQUEST: 'EMPLEADO_CREATE_REQUEST',
  EMPLEADO_CREATE_SUCCESS: 'EMPLEADO_CREATE_SUCCESS',
  EMPLEADO_CREATE_ERROR: 'EMPLEADO_CREATE_ERROR',

  EMPLEADO_UPDATE_REQUEST: 'EMPLEADO_UPDATE_REQUEST',
  EMPLEADO_UPDATE_SUCCESS: 'EMPLEADO_UPDATE_SUCCESS',
  EMPLEADO_UPDATE_ERROR: 'EMPLEADO_UPDATE_ERROR',

  EMPLEADOS_FETCH_REQUEST: 'EMPLEADOS_FETCH_REQUEST',
  EMPLEADOS_FETCH_SUCCESS: 'EMPLEADOS_FETCH_SUCCESS',
  EMPLEADOS_FETCH_ERROR: 'EMPLEADOS_FETCH_ERROR',

  EMPLEADO_UPDATE_MODAL: 'EMPLEADO_UPDATE_MODAL',
  EMPLEADO_SHOW_MODAL: 'EMPLEADO_SHOW_MODAL',
  EMPLEADO_HIDE_MODAL: 'EMPLEADO_HIDE_MODAL',

  EMPLEADO_DELETE_REQUEST: 'EMPLEADO_DELETE_REQUEST',
  EMPLEADO_DELETE_SUCCESS: 'EMPLEADO_DELETE_SUCCESS',
  EMPLEADO_DELETE_ERROR: 'EMPLEADO_DELETE_ERROR',

  // Acciones
  addEmpleado: data => ({
    types: [action.EMPLEADO_CREATE_REQUEST, action.EMPLEADO_CREATE_SUCCESS, action.EMPLEADO_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/empleados`,
        data,
      }
    }
  }),
  editEmpleado: data => ({
    types: [action.EMPLEADO_UPDATE_REQUEST, action.EMPLEADO_UPDATE_SUCCESS, action.EMPLEADO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/empleados/${data.id}`,
        data,
      }
    }
  }),
  deleteEmpleado: data => ({
    types: [action.EMPLEADO_DELETE_REQUEST, action.EMPLEADO_DELETE_SUCCESS, action.EMPLEADO_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/empleados/${data.id}`,
      }
    }
  }),
  getEmpleados: () => ({
    types: [action.EMPLEADOS_FETCH_REQUEST, action.EMPLEADOS_FETCH_SUCCESS, action.EMPLEADOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/empleados`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.EMPLEADO_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.EMPLEADO_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.EMPLEADO_HIDE_MODAL });
    }
  },
};
export default action;
