// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
  filtrosBusqueda: false,
};

export default function solicitudReducer(state = init, action) {
  const { payload } = action;
  switch (action.type) {
    // FETCH
    case actions.SERVICIOS_FETCH_REQUEST:
      return { ...state, isFetching: true, success: false };

    case actions.SERVICIOS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        isFetching: false,
        success: true
      };
    case actions.SERVICIOS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false, success: false };

    // CREATE
    // case actions.SERVICIO_CREATE_REQUEST:
    //   return { ...state, creating: true };

    case actions.SERVICIO_CREATE_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        creating: false,
        modalVisible: false,
        visible: false,
      };
    case actions.SERVICIO_CREATE_ERROR:
      return { ...state, err: true };

    // UPDATE
    // case actions.SERVICIO_UPDATE_REQUEST:
    //   return { ...state, creating: true };

    case actions.SERVICIO_UPDATE_SUCCESS:
      return {
        ...state,
        list: payload.data.data,
        // isFetching: false,
        modalVisible: false,
      };
    case actions.SERVICIO_UPDATE_ERROR:
      return { ...state, err: true };

    // UPDATE SERVICIO POBLACIOn
    // case actions.SERVICIO_POBLACION_UPDATE_REQUEST:
    //   return { ...state, isLoading: true };

    case actions.SERVICIO_POBLACION_UPDATE_SUCCESS:
      return {
        ...state,
        currentItem: payload.data.currentItem,
        list: payload.data.data,
        isLoading: false,
      };
    case actions.SERVICIO_POBLACION_UPDATE_ERROR:
      return { ...state, list: [], isFetching: false };

    // UPDATE
    case actions.SERVICIO_UPDATE_MODAL:
      return {
        ...state,
        currentItem: action.payload,
        modalVisible: true,
        modalType: 'update',
      };

    // MODAL
    case actions.SERVICIO_SHOW_MODAL:
      return { ...state, modalVisible: true, modalType: 'create' };

    case actions.SERVICIO_SHOW_MODAL_CLONE:
      return { ...state, visible: !state.visible, currentItem: action.payload, };

    case actions.SERVICIO_HIDE_MODAL:
      return { ...state, modalVisible: false, modalType: 'create' };

    case actions.SERVICIO_ABRIR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: true };

    case actions.SERVICIO_CERRAR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: false };

    default:
      return state;
  }
}
