const action = {
  CANCELACIONES_FETCH_REQUEST: 'CANCELACIONES_FETCH_REQUEST',
  CANCELACIONES_FETCH_SUCCESS: 'CANCELACIONES_FETCH_SUCCESS',
  CANCELACIONES_FETCH_ERROR: 'CANCELACIONES_FETCH_ERROR',

  // Acciones
  getCancelaciones: data => ({
    types: [action.CANCELACIONES_FETCH_REQUEST, action.CANCELACIONES_FETCH_SUCCESS, action.CANCELACIONES_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/historial_cancelacion?page=${data.page}&limit=${data.limit}`,
      }
    }
  }),
};
export default action;
