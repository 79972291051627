import { CERRAR_SIDEBAR, ABRIR_SIDEBAR } from '../app/reducer';
const action = {
  MOVIMIENTOS_FETCH_REQUEST: 'MOVIMIENTOS_FETCH_REQUEST',
  MOVIMIENTOS_FETCH_SUCCESS: 'MOVIMIENTOS_FETCH_SUCCESS',
  MOVIMIENTOS_FETCH_ERROR: 'MOVIMIENTOS_FETCH_ERROR',

  // Abrir filtros
  abrirFiltros: () => {
    return (dispatch) => {
      dispatch({ type: CERRAR_SIDEBAR });
    }
  },
  // Cerrar filtros
  cerrarFiltros: () => {
    return (dispatch) => {
      dispatch({ type: ABRIR_SIDEBAR });
    }
  },
};
export default action;
