// import axios from "axios";
import { push } from 'react-router-redux';
// import config from '../../settings'

const action = {
  SEARCH_QUERY_REQUEST: "SEARCH_QUERY_REQUEST",
  SELECT_CORRIDA_REQUEST: "SELECT_CORRIDA_REQUEST",

  SELECT_ASIENTO_REQUEST: "SELECT_ASIENTO_REQUEST",
  SELECT_ASIENTO_SUCCESS: "SELECT_ASIENTO_SUCCESS",
  SELECT_ASIENTO_ERROR: "SELECT_ASIENTO_ERROR",

  RESERVACIONES_FETCH_REQUEST: "RESERVACIONES_FETCH_REQUEST",
  RESERVACIONES_FETCH_SUCCESS: "RESERVACIONES_FETCH_SUCCESS",
  RESERVACIONES_FETCH_ERROR: "RESERVACIONES_FETCH_ERROR",

  RESERVACION_CREATE_REQUEST: "RESERVACION_CREATE_REQUEST",
  RESERVACION_CREATE_SUCCESS: "RESERVACION_CREATE_SUCCESS",
  RESERVACION_CREATE_ERROR: "RESERVACION_CREATE_ERROR",

  RESERVACION_DELETE_REQUEST: "RESERVACION_DELETE_REQUEST",
  RESERVACION_DELETE_SUCCESS: "RESERVACION_DELETE_SUCCESS",
  RESERVACION_DELETE_ERROR: "RESERVACION_DELETE_ERROR",

  RESERVACION_UPDATE_REQUEST: "RESERVACION_UPDATE_REQUEST",
  RESERVACION_UPDATE_SUCCESS: "RESERVACION_UPDATE_SUCCESS",
  RESERVACION_UPDATE_ERROR: "RESERVACION_UPDATE_ERROR",

  RESERVACION_CANCEL_REQUEST: "RESERVACION_CANCEL_REQUEST",
  RESERVACION_CANCEL_SUCCESS: "RESERVACION_CANCEL_SUCCESS",
  RESERVACION_CANCEL_ERROR: "RESERVACION_CANCEL_ERROR",

  RESERVACION_PAGAR_REQUEST: "RESERVACION_PAGAR_REQUEST",
  RESERVACION_PAGAR_SUCCESS: "RESERVACION_PAGAR_SUCCESS",
  RESERVACION_PAGAR_ERROR: "RESERVACION_PAGAR_ERROR",

  RESERVACION_CLEAR_SUCCESS: "RESERVACION_CLEAR_SUCCESS",

  // Actualizar store redux
  clearCompra: () => dispatch => dispatch({ type: action.RESERVACION_CLEAR_SUCCESS }),

  searchBoletos: (payload, pathname) => dispatch => {
    dispatch({ type: action.SEARCH_QUERY_REQUEST, payload });
    dispatch(push(`${pathname}`));
  },
  selectCorrida: (payload, pathname) => dispatch => {
    dispatch({ type: action.SELECT_CORRIDA_REQUEST, payload });
    dispatch(push(`${pathname}`));
  },
  selectAsiento: (data) => ({
    types: [action.RESERVACION_CREATE_REQUEST, action.RESERVACION_CREATE_SUCCESS, action.RESERVACION_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/reservaciones`,
        data,
      }
    }
  }),
  deleteAsiento: (id, fecha) => ({
    types: [action.RESERVACION_DELETE_REQUEST, action.RESERVACION_DELETE_SUCCESS, action.RESERVACION_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/reservaciones/${id}?fecha=${fecha}`,
      }
    }
  }),
  cancelAsiento: (data) => ({
    types: [action.RESERVACION_CANCEL_REQUEST, action.RESERVACION_CANCEL_SUCCESS, action.RESERVACION_CANCEL_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: '/reservaciones/cancelar',
        data,
      }
    }
  }),

  getReservaciones: (data) => ({
    types: [action.RESERVACIONES_FETCH_REQUEST, action.RESERVACIONES_FETCH_SUCCESS, action.RESERVACIONES_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/reservaciones?id=${data.id}${data.userId ? `&userId=${data.userId}` : ''}`,
      }
    }
  }),
  // reservaciones de los asientos
  getReservacionesAsientoAdmin: (data) => ({
    types: [action.RESERVACIONES_FETCH_REQUEST, action.RESERVACIONES_FETCH_SUCCESS, action.RESERVACIONES_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/reservacionesAsientoAdmin?id=${data.id}${data.userId ? `&userId=${data.userId}` : ''}${data.fecha ? `&fecha=${data.fecha}` : ''}${data.empresaId ? `&empresaId=${data.empresaId}` : ''}`,
      }
    }
  }),
  // reservaciones de los asientos
  getReservacionesAsiento: (data) => ({
    types: [action.RESERVACIONES_FETCH_REQUEST, action.RESERVACIONES_FETCH_SUCCESS, action.RESERVACIONES_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/reservacionesAsiento?id=${data.id}${data.userId ? `&userId=${data.userId}` : ''}${data.fecha ? `&fecha=${data.fecha}` : ''}${data.empresaId ? `&empresaId=${data.empresaId}` : ''}`,
      }
    }
  }),

  updateReservation: (data) => ({
    types: [action.RESERVACION_UPDATE_REQUEST, action.RESERVACION_UPDATE_SUCCESS, action.RESERVACION_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/reservaciones`,
        data,
      }
    }
  }),
  pagarReservation: (data) => ({
    types: [action.RESERVACION_PAGAR_REQUEST, action.RESERVACION_PAGAR_SUCCESS, action.RESERVACION_PAGAR_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/reservaciones/pagar`,
        data,
      }
    }
  }),
  pagarReservationLocal: (data) => ({
    types: [action.RESERVACION_PAGAR_REQUEST, action.RESERVACION_PAGAR_SUCCESS, action.RESERVACION_PAGAR_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/reservaciones/pagarLocal`,
        data,
      }
    }
  }),

};
export default action;
