// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  suscripciones: [],
  isFetching: true,
};

export default function solicitudReducer(state = init, action) {
  const { payload } = action;
  switch (action.type) {
    // FETCH
    case actions.PLANS_FETCH_REQUEST:
      return { ...state, isFetching: true };

    case actions.PLANS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        isFetching: false,
      };
    case actions.PLANS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false };

    // FETCH SUSCRIPCIONES
    case actions.SUSCRIPCIONES_FETCH_REQUEST:
      return { ...state, isFetching: true };

    case actions.SUSCRIPCIONES_FETCH_SUCCESS:
      return {
        ...state,
        suscripciones: payload.data.data || [],
        isFetching: false,
      };
    case actions.SUSCRIPCIONES_FETCH_ERROR:
      return { ...state, suscripciones: [], isFetching: false };
    // CREATE
    case actions.PLAN_CREATE_REQUEST:
      return { ...state, isFetching: true };

    case actions.PLAN_CREATE_SUCCESS:
      return {
        ...state,
        list: [...state.list, payload.data.data],
        isFetching: false,
        modalVisible: false,
      };
    case actions.PLAN_CREATE_ERROR:
      return { ...state, list: [], isFetching: false };

    // UPDATE
    case actions.PLAN_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case actions.PLAN_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(x => x.id === payload.data.data.id ? payload.data.data : x),
        isFetching: false,
        modalVisible: false,
      };
    case actions.PLAN_UPDATE_ERROR:
      return { ...state, list: [], isFetching: false };

    // UPDATE
    case actions.PLAN_UPDATE_MODAL:
      return {
        ...state,
        currentItem: action.payload,
        modalVisible: true,
        modalType: 'update',
      };

    // UPDATE
    case actions.PLAN_DELETE_REQUEST:
      return { ...state, isFetching: true };

    case actions.PLAN_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== payload.data.data),
        isFetching: false,
        modalVisible: false,
      };
    case actions.PLAN_DELETE_ERROR:
      return { ...state, list: [], isFetching: false };

    // MODAL
    case actions.PLAN_SHOW_MODAL:
      return { ...state, modalVisible: true, modalType: 'create' };

    case actions.PLAN_HIDE_MODAL:
      return { ...state, modalVisible: false, modalType: 'create' };

    default:
      return state;
  }
}
