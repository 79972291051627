const action = {
  UNIDAD_CREATE_REQUEST: 'UNIDAD_CREATE_REQUEST',
  UNIDAD_CREATE_SUCCESS: 'UNIDAD_CREATE_SUCCESS',
  UNIDAD_CREATE_ERROR: 'UNIDAD_CREATE_ERROR',

  UNIDAD_UPDATE_REQUEST: 'UNIDAD_UPDATE_REQUEST',
  UNIDAD_UPDATE_SUCCESS: 'UNIDAD_UPDATE_SUCCESS',
  UNIDAD_UPDATE_ERROR: 'UNIDAD_UPDATE_ERROR',

  UNIDADS_FETCH_REQUEST: 'UNIDADS_FETCH_REQUEST',
  UNIDADS_FETCH_SUCCESS: 'UNIDADS_FETCH_SUCCESS',
  UNIDADS_FETCH_ERROR: 'UNIDADS_FETCH_ERROR',

  UNIDAD_UPDATE_MODAL: 'UNIDAD_UPDATE_MODAL',
  UNIDAD_SHOW_MODAL: 'UNIDAD_SHOW_MODAL',
  UNIDAD_HIDE_MODAL: 'UNIDAD_HIDE_MODAL',

  UNIDAD_DELETE_REQUEST: 'UNIDAD_DELETE_REQUEST',
  UNIDAD_DELETE_SUCCESS: 'UNIDAD_DELETE_SUCCESS',
  UNIDAD_DELETE_ERROR: 'UNIDAD_DELETE_ERROR',

  // Acciones
  addUnidad: data => ({
    types: [action.UNIDAD_CREATE_REQUEST, action.UNIDAD_CREATE_SUCCESS, action.UNIDAD_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/unidades`,
        data,
      }
    }
  }),
  editUnidad: data => ({
    types: [action.UNIDAD_UPDATE_REQUEST, action.UNIDAD_UPDATE_SUCCESS, action.UNIDAD_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/unidades/${data.id}`,
        data,
      }
    }
  }),
  deleteUnidad: data => ({
    types: [action.UNIDAD_DELETE_REQUEST, action.UNIDAD_DELETE_SUCCESS, action.UNIDAD_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/unidades/${data.id}`,
      }
    }
  }),
  getUnidads: () => ({
    types: [action.UNIDADS_FETCH_REQUEST, action.UNIDADS_FETCH_SUCCESS, action.UNIDADS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/unidades`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.UNIDAD_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.UNIDAD_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.UNIDAD_HIDE_MODAL });
    }
  },
};
export default action;
