import { Map } from 'immutable';
import numeral from 'numeral';
import moment from 'moment';

// Calcular el costo del estacionamiento por fraccion
export function calcularTotalFraccion(precioHora, precioFraccion, tiempoMin, fraccion, minTolerancia) {
  const hrsCompletas = tiempoMin / 60;
  const hrsEnteras = parseInt(hrsCompletas);
  const hrsToDivide = hrsEnteras - 1;
  const incompleteHours = hrsCompletas - hrsEnteras;
  const totalFraccionesCompleteHr = (hrsToDivide * 60) / fraccion;
  const totalFraccionesIncompleteHr = (incompleteHours * 60) / fraccion;
  const fraccionesCompletas = parseInt(totalFraccionesIncompleteHr);
  const fraccionesIncompletas = totalFraccionesIncompleteHr - fraccionesCompletas;
  const fraccionesIncompletasEnMin = fraccionesIncompletas * fraccion;
  const fraccionToAdd = fraccionesIncompletasEnMin < (minTolerancia + 1) ? 0 : 1;
  const costoTotal = precioHora + (totalFraccionesCompleteHr * precioFraccion) + (fraccionesCompletas * precioFraccion) + (fraccionToAdd * precioFraccion);
  console.log('Fracción:', fraccion);
  console.log('Costo por hora:', precioHora);
  console.log('Costo por fraccion:', precioFraccion);
  console.log('Tolerancia:', minTolerancia);
  console.log('Tiempo en min:', tiempoMin);
  console.log('Horas completas:', hrsCompletas);
  console.log('Horas enteras:', hrsEnteras);
  console.log('Horas enteras para fracción:', hrsToDivide);
  console.log('Horas incompletas:', incompleteHours);
  console.log('Fracciones de hora completa:', totalFraccionesCompleteHr);
  console.log('Fracciones de hora incompleta:', totalFraccionesIncompleteHr);
  console.log('Fracciones  completas:', fraccionesCompletas);
  console.log('Fracciones  incompletas:', fraccionesIncompletas);
  console.log('Fracciones  incompletas en minutos:', number(fraccionesIncompletasEnMin));
  console.log('Fracciones  to add:', fraccionToAdd);
  console.log('Costo total:', costoTotal);
  // const total = tiempoMin < 60 ? precioHora : costoTotal
  return tiempoMin < 60 ? precioHora : costoTotal;
}

export function dataURLtoFile(dataurl, filename) {
     var arr = dataurl.split(','),
         mime = arr[0].match(/:(.*?);/)[1],
         bstr = atob(arr[1]),
         n = bstr.length,
         u8arr = new Uint8Array(n);
     while(n--){
         u8arr[n] = bstr.charCodeAt(n);
     }
     return new File([u8arr], filename, {type:mime});
 }

export const peso = val => `${numeral(val).format("$0,0.00")}`;
export const number = val => Number(`${numeral(val).format("00.00")}`);
export const anios = Array(10).fill().map((_, i) => moment().add(i, 'year').format('YY'));
export const meses = ["01","02","03","04","05","06","07","08","09","10","11","12"];

export function slugify(string) {
    const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
    const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        // .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        // .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}

export function clearToken() {
  localStorage.removeItem('id_token');
  localStorage.removeItem('empresaId');
  localStorage.removeItem('tiempo');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}
export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}
