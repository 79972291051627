const action = {
  MARCA_CREATE_REQUEST: 'MARCA_CREATE_REQUEST',
  MARCA_CREATE_SUCCESS: 'MARCA_CREATE_SUCCESS',
  MARCA_CREATE_ERROR: 'MARCA_CREATE_ERROR',

  MARCA_UPDATE_REQUEST: 'MARCA_UPDATE_REQUEST',
  MARCA_UPDATE_SUCCESS: 'MARCA_UPDATE_SUCCESS',
  MARCA_UPDATE_ERROR: 'MARCA_UPDATE_ERROR',

  MARCAS_FETCH_REQUEST: 'MARCAS_FETCH_REQUEST',
  MARCAS_FETCH_SUCCESS: 'MARCAS_FETCH_SUCCESS',
  MARCAS_FETCH_ERROR: 'MARCAS_FETCH_ERROR',

  MARCA_UPDATE_MODAL: 'MARCA_UPDATE_MODAL',
  MARCA_SHOW_MODAL: 'MARCA_SHOW_MODAL',
  MARCA_HIDE_MODAL: 'MARCA_HIDE_MODAL',

  // Acciones
  addMarca: data => ({
    types: [action.MARCA_CREATE_REQUEST, action.MARCA_CREATE_SUCCESS, action.MARCA_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/marcas`,
        data,
      }
    }
  }),
  editMarca: data => ({
    types: [action.MARCA_UPDATE_REQUEST, action.MARCA_UPDATE_SUCCESS, action.MARCA_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/marcas/${data.id}`,
        data,
      }
    }
  }),
  deleteMarca: data => ({
    types: [action.MARCA_UPDATE_REQUEST, action.MARCA_UPDATE_SUCCESS, action.MARCA_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/marcas/${data.id}`,
      }
    }
  }),
  getMarcas: empresaId => ({
    types: [action.MARCAS_FETCH_REQUEST, action.MARCAS_FETCH_SUCCESS, action.MARCAS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/marcas`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.MARCA_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.MARCA_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.MARCA_HIDE_MODAL });
    }
  },
};
export default action;
