const action = {
  PAQUETE_CREATE_REQUEST: 'PAQUETE_CREATE_REQUEST',
  PAQUETE_CREATE_SUCCESS: 'PAQUETE_CREATE_SUCCESS',
  PAQUETE_CREATE_ERROR: 'PAQUETE_CREATE_ERROR',

  PAQUETE_UPDATE_REQUEST: 'PAQUETE_UPDATE_REQUEST',
  PAQUETE_UPDATE_SUCCESS: 'PAQUETE_UPDATE_SUCCESS',
  PAQUETE_UPDATE_ERROR: 'PAQUETE_UPDATE_ERROR',

  PAQUETES_FETCH_REQUEST: 'PAQUETES_FETCH_REQUEST',
  PAQUETES_FETCH_SUCCESS: 'PAQUETES_FETCH_SUCCESS',
  PAQUETES_FETCH_ERROR: 'PAQUETES_FETCH_ERROR',

  PAQUETE_UPDATE_MODAL: 'PAQUETE_UPDATE_MODAL',
  PAQUETE_SHOW_MODAL: 'PAQUETE_SHOW_MODAL',
  PAQUETE_HIDE_MODAL: 'PAQUETE_HIDE_MODAL',

  PAQUETE_DELETE_REQUEST: 'PAQUETE_DELETE_REQUEST',
  PAQUETE_DELETE_SUCCESS: 'PAQUETE_DELETE_SUCCESS',
  PAQUETE_DELETE_ERROR: 'PAQUETE_DELETE_ERROR',

  // Acciones
  addPaquete: data => ({
    types: [action.PAQUETE_CREATE_REQUEST, action.PAQUETE_CREATE_SUCCESS, action.PAQUETE_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/paquetes`,
        data,
      }
    }
  }),
  editPaquete: data => ({
    types: [action.PAQUETE_UPDATE_REQUEST, action.PAQUETE_UPDATE_SUCCESS, action.PAQUETE_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/paquetes/${data.id}`,
        data,
      }
    }
  }),
  deletePaquete: data => ({
    types: [action.PAQUETE_DELETE_REQUEST, action.PAQUETE_DELETE_SUCCESS, action.PAQUETE_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/paquetes/${data.id}`,
      }
    }
  }),
  getPaquetes: () => ({
    types: [action.PAQUETES_FETCH_REQUEST, action.PAQUETES_FETCH_SUCCESS, action.PAQUETES_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/paquetes`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.PAQUETE_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PAQUETE_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PAQUETE_HIDE_MODAL });
    }
  },
};
export default action;
