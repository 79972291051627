const action = {
  CATEGORIA_CREATE_REQUEST: 'CATEGORIA_CREATE_REQUEST',
  CATEGORIA_CREATE_SUCCESS: 'CATEGORIA_CREATE_SUCCESS',
  CATEGORIA_CREATE_ERROR: 'CATEGORIA_CREATE_ERROR',

  CATEGORIA_UPDATE_REQUEST: 'CATEGORIA_UPDATE_REQUEST',
  CATEGORIA_UPDATE_SUCCESS: 'CATEGORIA_UPDATE_SUCCESS',
  CATEGORIA_UPDATE_ERROR: 'CATEGORIA_UPDATE_ERROR',

  CATEGORIAS_FETCH_REQUEST: 'CATEGORIAS_FETCH_REQUEST',
  CATEGORIAS_FETCH_SUCCESS: 'CATEGORIAS_FETCH_SUCCESS',
  CATEGORIAS_FETCH_ERROR: 'CATEGORIAS_FETCH_ERROR',

  CATEGORIA_UPDATE_MODAL: 'CATEGORIA_UPDATE_MODAL',
  CATEGORIA_SHOW_MODAL: 'CATEGORIA_SHOW_MODAL',
  CATEGORIA_HIDE_MODAL: 'CATEGORIA_HIDE_MODAL',

  CATEGORIA_DELETE_REQUEST: 'CATEGORIA_DELETE_REQUEST',
  CATEGORIA_DELETE_SUCCESS: 'CATEGORIA_DELETE_SUCCESS',
  CATEGORIA_DELETE_ERROR: 'CATEGORIA_DELETE_ERROR',

  // Acciones
  addCategoria: data => ({
    types: [action.CATEGORIA_CREATE_REQUEST, action.CATEGORIA_CREATE_SUCCESS, action.CATEGORIA_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/categorias`,
        data,
      }
    }
  }),
  editCategoria: data => ({
    types: [action.CATEGORIA_UPDATE_REQUEST, action.CATEGORIA_UPDATE_SUCCESS, action.CATEGORIA_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/categorias/${data.id}`,
        data,
      }
    }
  }),
  deleteCategoria: data => ({
    types: [action.CATEGORIA_DELETE_REQUEST, action.CATEGORIA_DELETE_SUCCESS, action.CATEGORIA_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/categorias/${data.id}`,
      }
    }
  }),
  getCategorias: () => ({
    types: [action.CATEGORIAS_FETCH_REQUEST, action.CATEGORIAS_FETCH_SUCCESS, action.CATEGORIAS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/categorias`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.CATEGORIA_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.CATEGORIA_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.CATEGORIA_HIDE_MODAL });
    }
  },
};
export default action;
