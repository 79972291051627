import { CERRAR_SIDEBAR } from '../app/reducer';

const action = {
  RESERVACIONES_FETCH_REQUEST: 'RESERVACIONES_FETCH_REQUEST',
  RESERVACIONES_FETCH_SUCCESS: 'RESERVACIONES_FETCH_SUCCESS',
  RESERVACIONES_FETCH_ERROR: 'RESERVACIONES_FETCH_ERROR',

  RESERVACIONES_TOTAL_FETCH_REQUEST: 'RESERVACIONES_TOTAL_FETCH_REQUEST',
  RESERVACIONES_TOTAL_FETCH_SUCCESS: 'RESERVACIONES_TOTAL_FETCH_SUCCESS',
  RESERVACIONES_TOTAL_FETCH_ERROR: 'RESERVACIONES_TOTAL_FETCH_ERROR',

  OPEN_DRAWER_INFO: 'OPEN_DRAWER_INFO',
  CLOSE_DRAWER_INFO: 'CLOSE_DRAWER_INFO',

  // Acciones
  getReservaciones: data => ({
    types: [action.RESERVACIONES_FETCH_REQUEST, action.RESERVACIONES_FETCH_SUCCESS, action.RESERVACIONES_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/reservaciones?page=${data.page}&limit=${data.limit}${data.status ? `&status=${data.status}` : ''}${data.empresaId ? `&empresaId=${data.empresaId}` : ''}`,
      }
    }
  }),
  getReservacionesTotal: data => ({
    types: [action.RESERVACIONES_TOTAL_FETCH_REQUEST, action.RESERVACIONES_TOTAL_FETCH_SUCCESS, action.RESERVACIONES_TOTAL_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/reservaciones/total`,
      }
    }
  }),
  deteleReservacionAdmin: id => ({
    types: [action.RESERVACIONES_FETCH_REQUEST, action.RESERVACIONES_FETCH_SUCCESS, action.RESERVACIONES_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/reservacionesAdmin/${id}`,
      }
    }
  }),
  closeSidebar: () => dispatch => dispatch({ type: CERRAR_SIDEBAR }),
  openDrawer: payload => dispatch => dispatch({ type: action.OPEN_DRAWER_INFO, payload }),
  closeDrawer: payload => dispatch => dispatch({ type: action.CLOSE_DRAWER_INFO }),
};
export default action;
