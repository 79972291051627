const action = {
  DESCUENTO_CREATE_REQUEST: 'DESCUENTO_CREATE_REQUEST',
  DESCUENTO_CREATE_SUCCESS: 'DESCUENTO_CREATE_SUCCESS',
  DESCUENTO_CREATE_ERROR: 'DESCUENTO_CREATE_ERROR',

  DESCUENTO_UPDATE_REQUEST: 'DESCUENTO_UPDATE_REQUEST',
  DESCUENTO_UPDATE_SUCCESS: 'DESCUENTO_UPDATE_SUCCESS',
  DESCUENTO_UPDATE_ERROR: 'DESCUENTO_UPDATE_ERROR',

  DESCUENTOS_FETCH_REQUEST: 'DESCUENTOS_FETCH_REQUEST',
  DESCUENTOS_FETCH_SUCCESS: 'DESCUENTOS_FETCH_SUCCESS',
  DESCUENTOS_FETCH_ERROR: 'DESCUENTOS_FETCH_ERROR',

  DESCUENTO_UPDATE_MODAL: 'DESCUENTO_UPDATE_MODAL',
  DESCUENTO_SHOW_MODAL: 'DESCUENTO_SHOW_MODAL',
  DESCUENTO_HIDE_MODAL: 'DESCUENTO_HIDE_MODAL',

  // Acciones
  addCupon: data => ({
    types: [action.DESCUENTO_CREATE_REQUEST, action.DESCUENTO_CREATE_SUCCESS, action.DESCUENTO_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/descuentos`,
        data,
      }
    }
  }),
  editCupon: data => ({
    types: [action.DESCUENTO_UPDATE_REQUEST, action.DESCUENTO_UPDATE_SUCCESS, action.DESCUENTO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/descuentos/${data.id}`,
        data,
      }
    }
  }),
  deleteCupon: data => ({
    types: [action.DESCUENTO_UPDATE_REQUEST, action.DESCUENTO_UPDATE_SUCCESS, action.DESCUENTO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/descuentos/${data.id}`,
      }
    }
  }),
  getCupons: empresaId => ({
    types: [action.DESCUENTOS_FETCH_REQUEST, action.DESCUENTOS_FETCH_SUCCESS, action.DESCUENTOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/descuentos?empresaId=${empresaId}`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.DESCUENTO_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.DESCUENTO_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.DESCUENTO_HIDE_MODAL });
    }
  },
};
export default action;
