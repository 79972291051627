import { CERRAR_SIDEBAR, ABRIR_SIDEBAR } from '../app/reducer';
const action = {
  SERVICIOS_FETCH_REQUEST: 'SERVICIOS_FETCH_REQUEST',
  SERVICIOS_FETCH_SUCCESS: 'SERVICIOS_FETCH_SUCCESS',
  SERVICIOS_FETCH_ERROR: 'SERVICIOS_FETCH_ERROR',

  SERVICIO_CREATE_REQUEST: 'SERVICIO_CREATE_REQUEST',
  SERVICIO_CREATE_SUCCESS: 'SERVICIO_CREATE_SUCCESS',
  SERVICIO_CREATE_ERROR: 'SERVICIO_CREATE_ERROR',

  SERVICIO_UPDATE_REQUEST: 'SERVICIO_UPDATE_REQUEST',
  SERVICIO_UPDATE_SUCCESS: 'SERVICIO_UPDATE_SUCCESS',
  SERVICIO_UPDATE_ERROR: 'SERVICIO_UPDATE_ERROR',

  SERVICIO_POBLACION_UPDATE_REQUEST: 'SERVICIO_POBLACION_UPDATE_REQUEST',
  SERVICIO_POBLACION_UPDATE_SUCCESS: 'SERVICIO_POBLACION_UPDATE_SUCCESS',
  SERVICIO_POBLACION_UPDATE_ERROR: 'SERVICIO_POBLACION_UPDATE_ERROR',

  SERVICIO_UPDATE_MODAL: 'SERVICIO_UPDATE_MODAL',
  SERVICIO_SHOW_MODAL: 'SERVICIO_SHOW_MODAL',
  SERVICIO_SHOW_MODAL_CLONE: 'SERVICIO_SHOW_MODAL_CLONE',
  SERVICIO_HIDE_MODAL: 'SERVICIO_HIDE_MODAL',

  SERVICIO_ABRIR_FILTROS_BUSQUEDA: 'SERVICIO_ABRIR_FILTROS_BUSQUEDA',
  SERVICIO_CERRAR_FILTROS_BUSQUEDA: 'SERVICIO_CERRAR_FILTROS_BUSQUEDA',

  // Acciones
  searchServicios: (data) => ({
    types: [action.SERVICIOS_FETCH_REQUEST, action.SERVICIOS_FETCH_SUCCESS, action.SERVICIOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/search_corridas`,
        data,
      }
    }
  }),
  addServicio: data => ({
    types: [action.SERVICIO_CREATE_REQUEST, action.SERVICIO_CREATE_SUCCESS, action.SERVICIO_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/servicios`,
        data,
      }
    }
  }),
  editServicio: data => ({
    types: [action.SERVICIO_UPDATE_REQUEST, action.SERVICIO_UPDATE_SUCCESS, action.SERVICIO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/servicios/${data.id}`,
        data,
      }
    }
  }),
  updateServicioPoblacion: data => ({
    types: [action.SERVICIO_POBLACION_UPDATE_REQUEST, action.SERVICIO_POBLACION_UPDATE_SUCCESS, action.SERVICIO_POBLACION_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/corrida_poblacion/${data.id}`,
        data,
      }
    }
  }),
  deleteServicio: data => ({
    types: [action.SERVICIO_UPDATE_REQUEST, action.SERVICIO_UPDATE_SUCCESS, action.SERVICIO_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/servicios/${data.id}`,
      }
    }
  }),
  getServicios: data => ({
    types: [action.SERVICIOS_FETCH_REQUEST, action.SERVICIOS_FETCH_SUCCESS, action.SERVICIOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/servicios${data.empresaId ? `?empresaId=${data.empresaId}` : ''}${data.status ? `&status=${data.status}` : ''}`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.SERVICIO_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.SERVICIO_SHOW_MODAL });
    };
  },
  showModalClone: (payload) => {
    return (dispatch) => {
      dispatch({ type: action.SERVICIO_SHOW_MODAL_CLONE, payload });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.SERVICIO_HIDE_MODAL });
    }
  },
  // Abrir filtros
  abrirFiltros: () => {
    return (dispatch) => {
      dispatch({ type: action.SERVICIO_ABRIR_FILTROS_BUSQUEDA });
      dispatch({ type: CERRAR_SIDEBAR });
    }
  },
  // Cerrar filtros
  cerrarFiltros: () => {
    return (dispatch) => {
      dispatch({ type: action.SERVICIO_CERRAR_FILTROS_BUSQUEDA });
      dispatch({ type: ABRIR_SIDEBAR });
    }
  },
};
export default action;
