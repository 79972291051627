// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
};

export default function solicitudReducer(state = init, action) {
  const { payload, error } = action;
  switch (action.type) {
    // FETCH
    case actions.POBLACIONS_FETCH_REQUEST:
      return { ...state, isFetching: true };

    case actions.POBLACIONS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        isFetching: false,
      };
    case actions.POBLACIONS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false };
    // CREATE
    case actions.POBLACION_CREATE_REQUEST:
      return { ...state };

    case actions.POBLACION_CREATE_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        modalVisible: false,
      };
    case actions.POBLACION_CREATE_ERROR:
      return { ...state, error: true, notification: error.response.data.err };

    // UPDATE
    case actions.POBLACION_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case actions.POBLACION_UPDATE_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        isFetching: false,
        modalVisible: false,
      };
    case actions.POBLACION_UPDATE_ERROR:
      return { ...state, list: [], isFetching: false };

    // UPDATE
    case actions.POBLACION_UPDATE_MODAL:
      return {
        ...state,
        currentItem: action.payload,
        modalVisible: true,
        modalType: 'update',
      };

    // MODAL
    case actions.POBLACION_SHOW_MODAL:
      return { ...state, modalVisible: true, modalType: 'create', error: false };

    case actions.POBLACION_HIDE_MODAL:
      return { ...state, modalVisible: false, modalType: 'create' };

    default:
      return state;
  }
}
