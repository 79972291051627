// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
};

export default function solicitudReducer(state = init, action) {
  const { payload } = action;
  switch (action.type) {
    // FETCH
    case actions.CATEGORIAS_FETCH_REQUEST:
      return { ...state, isFetching: true };

    case actions.CATEGORIAS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        isFetching: false,
      };
    case actions.CATEGORIAS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false };

    // CREATE
    // case actions.CATEGORIA_CREATE_REQUEST:
    //   return { ...state, isFetching: true };

    case actions.CATEGORIA_CREATE_SUCCESS:
      return {
        ...state,
        list: [payload.data.data, ...state.list],
        modalVisible: false,
      };
    case actions.CATEGORIA_CREATE_ERROR:
      return { ...state, error: true};

    // UPDATE
    case actions.CATEGORIA_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case actions.CATEGORIA_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(x => x.id === payload.data.data.id ? payload.data.data : x),
        isFetching: false,
        modalVisible: false,
      };
    case actions.CATEGORIA_UPDATE_ERROR:
      return { ...state, list: [], isFetching: false };

    // UPDATE
    case actions.CATEGORIA_UPDATE_MODAL:
      return {
        ...state,
        currentItem: action.payload,
        modalVisible: true,
        modalType: 'update',
      };

    // UPDATE
    case actions.CATEGORIA_DELETE_REQUEST:
      return { ...state, isFetching: true };

    case actions.CATEGORIA_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== payload.data.data),
        isFetching: false,
        modalVisible: false,
      };
    case actions.CATEGORIA_DELETE_ERROR:
      return { ...state, list: [], isFetching: false };

    // MODAL
    case actions.CATEGORIA_SHOW_MODAL:
      return { ...state, modalVisible: true, modalType: 'create' };

    case actions.CATEGORIA_HIDE_MODAL:
      return { ...state, modalVisible: false, modalType: 'create' };

    default:
      return state;
  }
}
