import { CERRAR_SIDEBAR } from '../app/reducer';

const action = {
  TICKETS_FETCH_REQUEST: 'TICKETS_FETCH_REQUEST',
  TICKETS_FETCH_SUCCESS: 'TICKETS_FETCH_SUCCESS',
  TICKETS_FETCH_ERROR: 'TICKETS_FETCH_ERROR',

  MOVIMIENTOS_FETCH_REQUEST: 'MOVIMIENTOS_FETCH_REQUEST',
  MOVIMIENTOS_FETCH_SUCCESS: 'MOVIMIENTOS_FETCH_SUCCESS',
  MOVIMIENTOS_FETCH_ERROR: 'MOVIMIENTOS_FETCH_ERROR',

  TICKETS_TOTAL_FETCH_REQUEST: 'TICKETS_TOTAL_FETCH_REQUEST',
  TICKETS_TOTAL_FETCH_SUCCESS: 'TICKETS_TOTAL_FETCH_SUCCESS',
  TICKETS_TOTAL_FETCH_ERROR: 'TICKETS_TOTAL_FETCH_ERROR',

  OPEN_DRAWER_INFO: 'OPEN_DRAWER_INFO',
  CLOSE_DRAWER_INFO: 'CLOSE_DRAWER_INFO',

  MOVIMIENTOS_SHOW_MODAL: 'MOVIMIENTOS_SHOW_MODAL',
  MOVIMIENTOS_HIDE_MODAL: 'MOVIMIENTOS_HIDE_MODAL',

  UPDATE_LIST_TICKETS_SUCCESS: 'UPDATE_LIST_TICKETS_SUCCESS',
  UPDATE_LIST_MOVIMIENTOS_SUCCESS: 'UPDATE_LIST_MOVIMIENTOS_SUCCESS',

  // Acciones
  getTickets: data => ({
    types: [action.TICKETS_FETCH_REQUEST, action.TICKETS_FETCH_SUCCESS, action.TICKETS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/tickets/admin?page=${data.page}&limit=${data.limit}${data.status ? `&status=${data.status}` : ''}${data.empresaId ? `&empresaId=${data.empresaId}` : ''}`,
      }
    }
  }),
  getMovimientos: data => ({
    types: [action.MOVIMIENTOS_FETCH_REQUEST, action.MOVIMIENTOS_FETCH_SUCCESS, action.MOVIMIENTOS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/movimientos/admin?page=${data.page}&limit=${data.limit}${data.status ? `&status=${data.status}` : ''}${data.empresaId ? `&empresaId=${data.empresaId}` : ''}`,
      }
    }
  }),
  getCortesAll: data => ({
    types: [action.TICKETS_FETCH_REQUEST, action.TICKETS_FETCH_SUCCESS, action.TICKETS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/cortes/admin?page=${data.page}&limit=${data.limit}${data.status ? `&status=${data.status}` : ''}${data.empresaId ? `&empresaId=${data.empresaId}` : ''}`,
      }
    }
  }),
  getTicketsTotal: data => ({
    types: [action.TICKETS_TOTAL_FETCH_REQUEST, action.TICKETS_TOTAL_FETCH_SUCCESS, action.TICKETS_TOTAL_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/tickets/total`,
      }
    }
  }),
  closeSidebar: () => dispatch => dispatch({ type: CERRAR_SIDEBAR }),
  updateListTickets: payload => dispatch => dispatch({ type: action.UPDATE_LIST_TICKETS_SUCCESS, payload }),
  updateListMovimientos: payload => dispatch => dispatch({ type: action.UPDATE_LIST_MOVIMIENTOS_SUCCESS, payload }),
  openDrawer: payload => dispatch => dispatch({ type: action.OPEN_DRAWER_INFO, payload }),
  closeDrawer: payload => dispatch => dispatch({ type: action.CLOSE_DRAWER_INFO }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.MOVIMIENTOS_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.MOVIMIENTOS_HIDE_MODAL });
    }
  },
};
export default action;
