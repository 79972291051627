const action = {
  PLAN_CREATE_REQUEST: 'PLAN_CREATE_REQUEST',
  PLAN_CREATE_SUCCESS: 'PLAN_CREATE_SUCCESS',
  PLAN_CREATE_ERROR: 'PLAN_CREATE_ERROR',

  PLAN_UPDATE_REQUEST: 'PLAN_UPDATE_REQUEST',
  PLAN_UPDATE_SUCCESS: 'PLAN_UPDATE_SUCCESS',
  PLAN_UPDATE_ERROR: 'PLAN_UPDATE_ERROR',

  PLANS_FETCH_REQUEST: 'PLANS_FETCH_REQUEST',
  PLANS_FETCH_SUCCESS: 'PLANS_FETCH_SUCCESS',
  PLANS_FETCH_ERROR: 'PLANS_FETCH_ERROR',

  PLAN_UPDATE_MODAL: 'PLAN_UPDATE_MODAL',
  PLAN_SHOW_MODAL: 'PLAN_SHOW_MODAL',
  PLAN_HIDE_MODAL: 'PLAN_HIDE_MODAL',

  PLAN_DELETE_REQUEST: 'PLAN_DELETE_REQUEST',
  PLAN_DELETE_SUCCESS: 'PLAN_DELETE_SUCCESS',
  PLAN_DELETE_ERROR: 'PLAN_DELETE_ERROR',

  SUSCRIPCIONES_FETCH_REQUEST: 'SUSCRIPCIONES_FETCH_REQUEST',
  SUSCRIPCIONES_FETCH_SUCCESS: 'SUSCRIPCIONES_FETCH_SUCCESS',
  SUSCRIPCIONES_FETCH_ERROR: 'SUSCRIPCIONES_FETCH_ERROR',

  // Acciones
  addPlan: data => ({
    types: [action.PLAN_CREATE_REQUEST, action.PLAN_CREATE_SUCCESS, action.PLAN_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/planes`,
        data,
      }
    }
  }),
  editPlan: data => ({
    types: [action.PLAN_UPDATE_REQUEST, action.PLAN_UPDATE_SUCCESS, action.PLAN_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/planes/${data.id}`,
        data,
      }
    }
  }),
  deletePlan: data => ({
    types: [action.PLAN_DELETE_REQUEST, action.PLAN_DELETE_SUCCESS, action.PLAN_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/planes/${data.id}`,
      }
    }
  }),
  getPlans: data => ({
    types: [action.PLANS_FETCH_REQUEST, action.PLANS_FETCH_SUCCESS, action.PLANS_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/planes`,
      }
    }
  }),
  getSuscripciones: data => ({
    types: [action.SUSCRIPCIONES_FETCH_REQUEST, action.SUSCRIPCIONES_FETCH_SUCCESS, action.SUSCRIPCIONES_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/suscripciones`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.PLAN_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PLAN_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.PLAN_HIDE_MODAL });
    }
  },
};
export default action;
