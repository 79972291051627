import axios from "axios";
import config from "@/config";
// import { notification } from 'antd';
import { store } from "react-notifications-component";

export const API = axios.create({
  baseURL: config.apiUrl,
  // timeout: 1000,
  // headers: { token: localStorage.getItem('id_token') || undefined },
});

API.interceptors.request.use(
  function(config) {
    config.headers = {
      ...config.headers,
      token: localStorage.getItem("id_token") || undefined,
      empresaId: localStorage.getItem("empresaId") || undefined,
    };
    // you can also do other modification in config
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    console.log(response);
    store.addNotification({
      // title: "Error",
      // message: "Error",
      title: response.data.err.message,
      message: response.data.err.description,
      type: "danger", // 'default', 'success', 'info', 'warning'
      container: "bottom-left", // where to position the notifications
      animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
      animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
      dismiss: {
        duration: 4000,
      },
    });
    return Promise.reject(error.response.data);
  }
);
