// import clone from 'clone';
import actions from './actions';

const init = {
  list: [],
  isFetching: true,
  filtrosBusqueda: false,
};

export default function solicitudReducer(state = init, action) {
  const { payload } = action;
  switch (action.type) {
    // FETCH
    case actions.MOVIMIENOSS_FETCH_REQUEST:
      return { ...state, isFetching: true, success: false };

    case actions.MOVIMIENOSS_FETCH_SUCCESS:
      return {
        ...state,
        list: payload.data.data || [],
        isFetching: false,
        success: true
      };
    case actions.MOVIMIENOSS_FETCH_ERROR:
      return { ...state, list: [], isFetching: false, success: false };

    case actions.MOVIMIENOS_ABRIR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: true };

    case actions.MOVIMIENOS_CERRAR_FILTROS_BUSQUEDA:
        return { ...state, filtrosBusqueda: false };

    default:
      return state;
  }
}
