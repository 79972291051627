// import clone from 'clone';
import actions from './actions';

const initialState = {
  loading: false,
  query: { },
  corrida: { },
  reservaciones: [],
  selected: [],
  error: undefined,
  isFailed: false,
  isSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {

    case actions.SEARCH_QUERY_REQUEST:
      return {
        ...state,
        query: payload
      };
    case actions.SEARCH_QUERY_SUCCESS:
      return {
        ...state,
        query: payload
      };
    case actions.SEARCH_QUERY_ERROR:
      return {
        ...state,
        query: payload
      };
    case actions.SELECT_CORRIDA_REQUEST:
      return {
        ...state,
        corrida: payload,
        corridaId: payload.corridaId,
      };
    case actions.SELECT_ASIENTO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.SELECT_ASIENTO_SUCCESS:
      return {
        ...state,
        selected: action.selected,
        pasajeros: action.pasajeros,
        loading: false,
      };

    case actions.RESERVACIONES_FETCH_REQUEST:
      return { ...state, loading: true, finish: false };

    case actions.RESERVACIONES_FETCH_SUCCESS:
      return {
        ...state,
        reservaciones: payload.data.data || [],
        selected: payload.data.selected || [],
        loading: false,
      };
    case actions.RESERVACIONES_FETCH_ERROR:
      return { ...state, list: [], loading: false };

    // CREAR RESERVACION
    case actions.RESERVACION_CREATE_REQUEST:
      return { ...state, creating: true, isFailed: false };

    case actions.RESERVACION_CREATE_SUCCESS:
      return {
        ...state,
        reservaciones: payload.data.data || [],
        selected: payload.data.selected || [],
        creating: false,
        isFailed: false,
      };
    case actions.RESERVACION_CREATE_ERROR:
      return {
        ...state,
        creating: false,
        notificacion: error.response.data.err.notificacion,
        isFailed: true,
      };

    // ELIMINAR UNA RESERVACION
    case actions.RESERVACION_DELETE_REQUEST:
      return { ...state, loading: true };

    case actions.RESERVACION_DELETE_SUCCESS:
      return {
        ...state,
        reservaciones: payload.data.data || [],
        selected: payload.data.selected || [],
        loading: false,
      };
    case actions.RESERVACION_DELETE_ERROR:
      return { ...state, reservaciones: [], loading: false };

    // UPDATE UNA RESERVACION
    case actions.RESERVACION_UPDATE_REQUEST:
      return { ...state, loadingForm: true, isSuccess: false, };

    case actions.RESERVACION_UPDATE_SUCCESS:
      return {
        ...state,
        loadingForm: false,
        isSuccess: true,
      };
    case actions.RESERVACION_UPDATE_ERROR:
      return {
        ...state,
        loadingForm: false,
        notificacion: error.response.data.err.notificacion,
        isFailed: true,
    };

    // UPDATE UNA RESERVACION
    case actions.RESERVACION_CANCEL_REQUEST:
      return { ...state, loadingCancel: true, isCancel: false, };

    case actions.RESERVACION_CANCEL_SUCCESS:
      return {
        ...state,
        loadingCancel: false,
        isCancel: true,
        selected: [],
      };
    case actions.RESERVACION_CANCEL_ERROR:
      return {
        ...state,
        loadingCancel: false,
        notificacion: error.response.data.err.notificacion,
        isFailed: true,
    };
    // UPDATE UNA RESERVACION
    case actions.RESERVACION_PAGAR_REQUEST:
      return { ...state, loading: true, isSuccess: false, finish: false, isFailed: false };

    case actions.RESERVACION_PAGAR_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        query: {},
        corrida: {},
        corridaId: null,
        selected: payload.data.data,
        orderId: payload.data.orderId,
        finish: true,
      };
    case actions.RESERVACION_PAGAR_ERROR:
      return {
        ...state,
        loading: false,
        notificacion: error.response.data.notificacion,
        isFailed: true,
        finish: false,
    };

    case actions.RESERVACION_CLEAR_SUCCESS:
      return {
        ...state,
        query: {},
        corrida: {},
        corridaId: null,
        selected: [],
      };

    default:
      return state;
  }
};
