export default {
  apiUrl: process.env.REACT_APP_API_URL,
  socketUrl: "https://estacionate.pinedapp.com",
  // apiUrl: "http://localhost:4001/api",
  // socketUrl: "http://localhost:4001",
};

const urlCliente = process.env.REACT_APP_CLIENTE_URL;
// const urlCliente = 'http://localhost:8080';

const siteConfig = {
  siteName: "Estacionate",
  siteIcon: "ion-flash",
  footerText: "Estacionate ©2019",
};

const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault",
};
const language = "spanish";

const jwtConfig = {
  fetchUrl: "/api/",
  secretKey: "secretKey",
};
const PAGE_SIZE = 9;

export { language, themeConfig, siteConfig, jwtConfig, urlCliente, PAGE_SIZE };
