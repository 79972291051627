import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { store as storeNot } from 'react-notifications-component';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { multiClientMiddleware } from "redux-axios-middleware";
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../redux/reducers';
import rootSaga from '../redux/sagas';
import clients from "./clients";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

const reducer = persistReducer(
  {
    key: 'rrsb', // key is required
    storage, // storage is now required
    whitelist: ['auth'],
  },
  combineReducers({
    ...rootReducer,
    form: formReducer
  })
);

/* simplified React Promise Middleware */
function promiseMiddleware({dispatch}) {
  function isPromise(val) {
    return val && typeof val.then === 'function';
  }

  return next => action => {
    return isPromise(action.payload)
      ? action.payload.then(
          result => dispatch({...action, payload: result}),
          error => dispatch({...action, payload: error, error: true })
        )
      : next(action);
  };
}

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      ({ getState, dispatch }, req) => {
        if (getState().auth.token) {
          req.headers["token"] = getState().auth.token;
          req.headers["empresaId"] = getState().auth.currentUser.empresa.id;
        }
        return req;
      }
    ],
    response: [
      {
        success: ({ getState, dispatch }, response) => {
          // console.log('respuesta', response);
          // Response interception
          return response
        },
        error: ({ dispatch }, error) => {
          const { response } = error;
          console.log(response);

          // Response Error Interception
          // console.log(`😱 Axios request failed: ${error}`);
          storeNot.addNotification({
            title:  response.data.err.message || 'Error',
            message:  response.data.err.description || 'Error',
            type: 'danger',                         // 'default', 'success', 'info', 'warning'
            container: 'bottom-left',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 5000
            }
          });
          return Promise.reject(error)
        }
      }
    ]
  }
};

const middlewares = [thunk, sagaMiddleware, routeMiddleware, promiseMiddleware, multiClientMiddleware(clients, axiosMiddlewareOptions),];

const enhancers = [];
/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  middlewares.push(createLogger({ collapsed: true }));
}

// Dev tools are helpful
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

// const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const composeEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const createStoreWithMiddleware = composeEnhancers(createStore);

  const store = createStoreWithMiddleware(reducer, initialState);

  sagaMiddleware.run(rootSaga);

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();
export { store, persistor, history };
