const action = {
  CLIENTE_CREATE_REQUEST: 'CLIENTE_CREATE_REQUEST',
  CLIENTE_CREATE_SUCCESS: 'CLIENTE_CREATE_SUCCESS',
  CLIENTE_CREATE_ERROR: 'CLIENTE_CREATE_ERROR',

  CLIENTE_UPDATE_REQUEST: 'CLIENTE_UPDATE_REQUEST',
  CLIENTE_UPDATE_SUCCESS: 'CLIENTE_UPDATE_SUCCESS',
  CLIENTE_UPDATE_ERROR: 'CLIENTE_UPDATE_ERROR',

  CLIENTES_FETCH_REQUEST: 'CLIENTES_FETCH_REQUEST',
  CLIENTES_FETCH_SUCCESS: 'CLIENTES_FETCH_SUCCESS',
  CLIENTES_FETCH_ERROR: 'CLIENTES_FETCH_ERROR',

  CLIENTE_UPDATE_MODAL: 'CLIENTE_UPDATE_MODAL',
  CLIENTE_SHOW_MODAL: 'CLIENTE_SHOW_MODAL',
  CLIENTE_HIDE_MODAL: 'CLIENTE_HIDE_MODAL',

  CLIENTE_DELETE_REQUEST: 'CLIENTE_DELETE_REQUEST',
  CLIENTE_DELETE_SUCCESS: 'CLIENTE_DELETE_SUCCESS',
  CLIENTE_DELETE_ERROR: 'CLIENTE_DELETE_ERROR',

  // Acciones
  addCliente: data => ({
    types: [action.CLIENTE_CREATE_REQUEST, action.CLIENTE_CREATE_SUCCESS, action.CLIENTE_CREATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "POST",
        url: `/clientes`,
        data,
      }
    }
  }),
  editCliente: data => ({
    types: [action.CLIENTE_UPDATE_REQUEST, action.CLIENTE_UPDATE_SUCCESS, action.CLIENTE_UPDATE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "PUT",
        url: `/clientes/${data.id}`,
        data,
      }
    }
  }),
  deleteCliente: data => ({
    types: [action.CLIENTE_DELETE_REQUEST, action.CLIENTE_DELETE_SUCCESS, action.CLIENTE_DELETE_ERROR],
    payload: {
      client: "default",
      request: {
        method: "DELETE",
        url: `/clientes/${data.id}`,
      }
    }
  }),
  getClientes: (id) => ({
    types: [action.CLIENTES_FETCH_REQUEST, action.CLIENTES_FETCH_SUCCESS, action.CLIENTES_FETCH_ERROR],
    payload: {
      client: "default",
      request: {
        method: "GET",
        url: `/clientes`,
      }
    }
  }),
  updateModal: payload => ({
    type: action.CLIENTE_UPDATE_MODAL,
    payload
  }),
  showModal: () => {
    return (dispatch) => {
      dispatch({ type: action.CLIENTE_SHOW_MODAL });
    };
  },
  hideModal: () => {
    return (dispatch) => {
      dispatch({ type: action.CLIENTE_HIDE_MODAL });
    }
  },
};
export default action;
