import actions from './actions';

const initialState = {
  isAuthenticated: false,
  currentUser: {},
  error: false,
  loading: false,
  hasCava: false,
  token: null,
  create: false,
  menus: [],
};

export default (state = initialState, action) => {
  const { payload, error } = action;
  switch (action.type) {

    case actions.USER_MENU_SUCCESS:
      return {
        ...state,
        menus: payload.data.data.map(i => (i.menu)),
        permisos: payload.data.data,
      };
    case actions.AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: action.authenticated
      };

    case actions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user,
        token: action.token,
      };

    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isAuthenticated: false,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        empresa: action.payload.empresa,
        empresaId: action.payload.empresa.id,
        isAuthenticated: true,
        token: action.token,
        modalVisible: false,
        rol: action.payload.tipo,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actions.LOGIN_SUSCRIPCION_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };

    case actions.REGISTRO_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actions.REGISTRO_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        isAuthenticated: true,
        token: action.token,
        modalVisible: false,
        rol: action.payload.tipo,
      };
    case actions.REGISTRO_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        notificacion: payload,
      };

    case actions.REGISTRO_EMPRESA_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        create: false,
      };
    case actions.REGISTRO_EMPRESA_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        empresaId: action.payload.empresa.id,
        isAuthenticated: true,
        token: action.token,
        modalVisible: false,
        rol: action.payload.tipo,
        create: true,
      };
    case actions.REGISTRO_EMPRESA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        notificacion: payload,
        create: false,
      };

    case actions.REGISTRO_DIRECCION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
      };
    case actions.UPDATE_DIRECCION_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case actions.UPDATE_DIRECCION_SUCCESS:
    console.log('Action', payload);
      return {
        ...state,
        loading: false,
        currentUser: payload.data.data,
        success: true,
        notificacion: payload.data.notificacion,
      };
    case actions.UPDATE_DIRECCION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: error.response.data.err.description,
        notificacion: error.response.data.err.notificacion,
      };
    // Actualizar datos de perfil usuario
    case actions.UPDATE_PERFIL_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };
    case actions.RECUPERAR_PASS_REQUEST:
      return { ...state, loading: true, success: false };
    case actions.RECUPERAR_PASS_SUCCESS:
      return { ...state, loading: false, success: true };

    case actions.RESET_PASS_REQUEST:
      return { ...state, loading: true, success: false };
    case actions.RESET_PASS_SUCCESS:
      return { ...state, loading: false, success: true };
    case actions.RESET_PASS_ERROR:
      return { ...state, loading: false, success: false };

    case actions.OPEN_MODAL_ASIENTO:
      return {
        ...state,
        modalVisible: !state.modalVisible,
        asiento: action.payload
      };
    case actions.OPEN_MODAL_SUCCESS:
      return {
        ...state,
        modalVisible: !state.modalVisible
      };

    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
